<template>
  <div class="container mt-3">
    <div class="d-flex">
      <div class="input-group w-25 mb-auto" v-if="false">
        <p-icon class="input-group-text" name="search"/>
        <input type="text" class="form-control" placeholder="Поиск">
      </div>
    </div>
    <p-table :data="state.tickets" :columns="ticketsColumns">
      <template v-slot:col-open="{ row }" class="text-center">
        <router-link
          :to="{ name: 'ticket', params: { id: row.id } }"
        >
          <p-icon class="text-center w-100" name="launch"/>
        </router-link>
      </template>
    </p-table>
  </div>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import PTable from '@/components/ui/Table'
import { onMounted, reactive } from 'vue'
import { formatAddress, formatName } from '@/common'

const ticketsColumns = [
  {
    name: 'Адрес',
    key: 'address',
    getter: formatAddress,
  },
  {
    name: 'Заказчик',
    key: 'customer',
    getter: formatName,
  },
  {
    name: 'Работа',
    key: 'workType',
  },
  {
    name: 'УК',
    key: 'managerName',
  },
  {
    name: 'Исполнитель',
    key: 'worker',
    getter (row) {
      return row.worker ? formatName(row.worker) : 'не назначен'
    },
  },
  {
    name: 'Дата',
    key: 'date',
    getter (row) {
      const date = new Date(row.date)
      console.log(date)
      return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`
    },
  },
  {
    name: 'Просмотр',
    key: 'open',
  },
]

const state = reactive({
  tickets: [],
})

const load = async () => {
  state.tickets = (await api.post(
    urls.TICKETS.LIST, ['new', 'todo', 'in_progress', 'lab_review', 'manager_review', 'done', 'wait'],
    { params: { deleted: true } }
  )).reverse()
}
onMounted(load)
</script>

<style scoped>

</style>
